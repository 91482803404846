(() => {
  const html = document.querySelector("html");
  const buttonDownBanner = document.querySelector(
    ".banner .banner--down-button"
  );
  const iconDownBanner = buttonDownBanner?.firstElementChild;

  buttonDownBanner?.addEventListener("mouseover", function () {
    iconDownBanner.className = "bx bx-tada bx-chevron-down";
  });

  buttonDownBanner?.addEventListener("mouseleave", function () {
    iconDownBanner.className = "bx bx-chevron-down";
  });

  //* ================== transport with button ===================
  buttonDownBanner?.addEventListener("click", function () {
    html.scrollTop = window.innerHeight;
  });
})();
